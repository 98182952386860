import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  token: any;
  accessToken: any;

  constructor() {}

  setToken(token: any) {
    //  this.token = token.replace(/ /gim, '+');
    // localStorage.setItem('token', this.token);
    this.token = token.replace(/ /gim, '+');
  }

  getToken() {
    // return localStorage.getItem('token');
    return this.token;
  }

  setAccessToken(token: any) {
    this.accessToken = token;
  }
  getAccessToken() {
    return this.accessToken;
  }
}
